import assert from 'assert'
import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { Layout } from '../components/Layout'
import { RingBaseSelect } from '../components/RingBaseSelect'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'
import type { Static1Query, ImageSharp } from '../../types/graphql-types'
import type { PageProps, HeadProps } from 'gatsby'

type Section = {
    title: string
    body: string
    image: ImageSharp
}

type Page = {
    page: string
    title: string
    metaTitle: string
    metaDescription: string
    catchText: string
    catchImage: ImageSharp
    sections: Section[]
}

function convertPage(data: Static1Query): Page {
    const { edges: staticImageEdges } = data.allFile
    const getStaticImage = (filename: string) => {
        return staticImageEdges.find((edge) => {
            return edge.node.relativePath === filename
        })?.node?.childImageSharp
    }

    const static1 = data.static1Json
    assert(static1)
    assert(static1.page)
    assert(static1.title)
    assert(static1.metaTitle)
    assert(static1.metaDescription)
    assert(static1.catchText)
    assert(static1.sections)
    const catchImage = getStaticImage(
        'static/' + static1.page + '/' + static1.catchImage
    )
    const sections = static1.sections.map((section) => {
        assert(section)
        assert(section.title)
        assert(section.body)
        assert(section.image)
        return {
            title: section.title,
            body: section.body,
            image: getStaticImage(
                'static/' + static1.page + '/' + section.image
            ) as ImageSharp,
        }
    })
    return {
        page: static1.page,
        title: static1.title,
        metaTitle: static1.metaTitle,
        metaDescription: static1.metaDescription,
        catchText: static1.catchText,
        catchImage: catchImage as ImageSharp,
        sections,
    }
}

export const Head: React.FC<HeadProps<Static1Query>> = ({ data }) => {
    const page = convertPage(data)
    return (
        <SEO
            title={page.metaTitle}
            description={page.metaDescription}
            page={page.page}
        />
    )
}

const Static1Page: React.FC<PageProps<Static1Query>> = ({ data }) => {
    const page = convertPage(data)
    const catchImage = getImage(page.catchImage.gatsbyImageData)
    return (
        <Layout breadcrumbs={[{ name: page.title }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <HeroWrapper>
                        <Title>
                            {page.catchText.split('\n').map((txt, index) => (
                                <TitleTxt key={index}>{txt}</TitleTxt>
                            ))}
                        </Title>
                        {catchImage && (
                            <GatsbyImage image={catchImage} alt="" />
                        )}
                    </HeroWrapper>
                    {page.sections.map((section, index) => {
                        const image = getImage(section.image.gatsbyImageData)
                        return (
                            <SectionWrapper key={index} odd={index % 2 === 0}>
                                <SectionTitle>
                                    <Txt>{section.title}</Txt>
                                </SectionTitle>
                                <SectionBody>
                                    {section.body
                                        .split('\n')
                                        .map((txt, index) => (
                                            <SectionTxt key={index}>
                                                {txt}
                                            </SectionTxt>
                                        ))}
                                </SectionBody>
                                {image && (
                                    <SectionImageWrapper>
                                        <GatsbyImage image={image} alt="" />
                                    </SectionImageWrapper>
                                )}
                            </SectionWrapper>
                        )
                    })}
                    <RingBaseSelect marginTop="120px" />
                </Grid>
            </Grid>
        </Layout>
    )
}

export const query = graphql`
    query Static1($slug: String, $filePath: String) {
        static1Json(page: { eq: $slug }) {
            page
            title
            metaTitle
            metaDescription
            catchImage
            catchText
            sections {
                body
                image
                title
            }
        }
        allFile(filter: { relativePath: { glob: $filePath } }) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(width: 680, formats: [AUTO, WEBP, AVIF])
                    }
                    relativePath
                }
            }
        }
    }
`
const HeroWrapper = styled('div')({})

const Title = styled('h1')({
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: '12px',
})
const TitleTxt = styled(Txt)({
    marginBottom: '4px',
})
type SectionWrapperProps = {
    odd: boolean
}
const SectionWrapper = styled('div')<SectionWrapperProps>(({ odd }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '48px',
    alignItems: odd ? 'flex-end' : 'flex-start',
}))
const SectionTitle = styled('h2')({
    margin: '0 12px',
    paddingBottom: '4px',
    borderBottom: '1px solid #aaaaaa',
})
const SectionBody = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
})
const SectionTxt = styled(Txt)({
    marginBottom: '4px',
})
const SectionImageWrapper = styled('div')({
    width: '80%',
})

export default Static1Page
